
import {
  defineComponent,
  onMounted,
  ref,
  useAttrs,
  watch,
  computed,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import {
  formatUtcDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2";
import { ApiPayment } from "@/core/api";
import AddPaymentModal from "./AddPaymentModal.vue";
import {
  PaymentOverview,
  paymentOverviewData,
  RelatedRefundItem,
  PaymentStatus,
} from "@/core/directive/interface/payment";
import {
  getPaymentStatus,
  getRefundStatus,
} from "@/core/directive/function/paymentManagement";
import _ from "lodash";
import useClipboard from "vue-clipboard3";
import mixin from "@/mixins";

export default defineComponent({
  name: "payment-management-payment-overview",
  components: {
    AddPaymentModal,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const attrs = useAttrs();
    const loading = ref(true);
    const { toClipboard } = useClipboard();

    const { showServerErrorMsg } = mixin();

    const paymentEditModal = ref();
    const editId = ref("");

    const baseData = ref(Object.assign({}, _.cloneDeep(paymentOverviewData)));
    const relatedRefundData = ref<Array<RelatedRefundItem>>([]);

    watch(
      () => attrs.loading,
      (newValue) => {
        loading.value = newValue as boolean;
      }
    );

    watch(
      () => attrs.baseData,
      (newValue) => {
        const base = newValue as PaymentOverview;
        baseData.value = base;
        relatedRefundData.value = base.relation_refund;
      },
      { immediate: true }
    );

    const getShowInfo = async () => {
      loading.value = true;
      const { data } = await ApiPayment.showPayment({ id: route.params.id });
      loading.value = false;
      if (data.code == 0) {
        baseData.value = data.data;
      } else {
        showServerErrorMsg(data);
      }
    };

    const getPaymentStatusClass = computed(() => {
      return (status) => {
        return getPaymentStatus(status);
      };
    });

    const getRefundStatusClass = computed(() => {
      return (status) => {
        return getRefundStatus(status);
      };
    });

    const copyText = async (text: string) => {
      try {
        await toClipboard(text);
        Swal.fire({
          title: t("payment.copyMsg"),
          icon: "info",
          buttonsStyling: false,
          showCancelButton: false,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } catch (error) {
        console.error("Copy failed:", error);
      }
    };

    const onEditButtonClick = (id) => {
      editId.value = id;
      paymentEditModal.value?.modalShow();
    };

    onMounted(() => {
      if (!attrs.loading) {
        loading.value = false;
      }
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
    });

    return {
      t,
      loading,
      baseData,
      paymentEditModal,
      editId,
      relatedRefundData,
      getShowInfo,
      getPaymentStatusClass,
      getRefundStatusClass,
      formatUtcDate,
      formatDateTime,
      copyText,
      onEditButtonClick,
      PaymentStatus,
    };
  },
});
